.header__logo {
  margin: 0 auto;
  padding: 0;
  width: 126px;
  min-height: 24px;
}

@media screen and (min-width: 550px) {
  .header__logo {
    margin: 0;
    width: 173px;
    min-height: 33px;
  }
}
