.button {
  background-color: #000;
  color: #fff;
}

.button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.button:link {
  text-decoration: none;
}

.button:visited {
  text-decoration: none;
}
