.modal__body {
  position: relative;
  max-width: 282px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1e1e1e;
  background-color: #fefefe;
  border-radius: 10px;
}

@media screen and (min-width: 550px) {
  .modal__body {
    min-width: 430px;
  }
}
