.profile__about {
    margin: 0 auto;
    padding: 15px 0 36px 0;
    max-width: 282px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.21;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media screen and (min-width: 550px) {
    .profile__about{
        margin: 0;
        max-width: 415px;
        font-size: 18px;
        line-height: 1.2;
    }
}

@media screen and (min-width: 920px) {
    .profile__about{
        padding: 16px 0 0;
    }
}
