.modal__title {
    margin: 0;
    padding-top: 10px;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.2;
    color: #fff;
    text-align: left;
    display: flex;
    align-self: flex-start;
}