.modal {
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000050;
  z-index: 1;
  visibility: hidden;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
