.header__user {
  font-family: "Inter";
  font-style: normal;

  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
  margin: auto;
}
