.login-form__title {
  margin: 60px auto 20px;
  padding: 0;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}
