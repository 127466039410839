.header__logout-button {
  margin: auto;
  font-family: "Inter", "Arial", "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  color: #a9a9a9;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
}
