.footer {
    margin: 0 auto;
    padding: 50px 19px 36px;
    max-width: 320px;
}

@media screen and (min-width: 550px) {
    .footer {
        padding: 68px 0 60px;
        max-width: 500px;
    }
}

@media screen and (min-width: 920px) {
    .footer {
        max-width: 880px;
    }
}