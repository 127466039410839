.form__save-button_disabled {
  background-color: white;
  color: #000000;
  border: 1px solid #000000;
  opacity: 0.5;
  cursor: default;
}

.form__save-button_disabled:hover {
  opacity: 0.5;
  cursor: default;
}
