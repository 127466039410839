.profile__avatar-button {
  background-image: url(../../../images/VectorAvatarEdit.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 40%;
  left: 38%;
  border: none;
  margin: 0;
  padding: 0;
}
