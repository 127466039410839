.modal__close-button {
    margin: 0;
    padding: 0;
    position: absolute;
    top: -35px;
    right: -10px;
    width: 36px;
    height: 36px;
    border: none;
    background-color: transparent;
    background-image: url(../../../images/close-button.svg);
    background-position: center;
    background-size: cover;
}

@media screen and (min-width: 550px) {
    .modal__close-button {
        width: 56px;
        height: 56px;
        top: -45px;
        right: -45px;
    }
}