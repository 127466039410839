.profile__info {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 920px) {
  .profile__info {
    align-items: flex-start;
  }
}
