@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: url(./Inter-Black.woff);
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url(./Inter-Medium.woff);
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url(./Inter-Regular.woff);
}