.header {
  margin: 0 auto;
  padding: 28px 0 32px 27px;
  max-width: 320px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 20px;
}

@media screen and (min-width: 550px) {
  .header {
    flex-direction: row;
    padding: 45px 0 41px;
    max-width: 500px;
  }
}

@media screen and (min-width: 920px) {
  .header {
    max-width: 880px;
  }
}
