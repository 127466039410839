.card__delete-button {
  background-image: url(../../../images/Trash.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  position: absolute;
  top: 18px;
  right: 15px;
  height: 18px;
  width: 19px;
  border: none;
}
