.profile__name {
    margin: 0 auto;
    padding: 0;
    max-width: 196px;
    font-size: 22px;
    line-height: 1.2;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media screen and (min-width: 550px) {
    .profile__name {
        margin: 0;
        max-width: 373px;
        font-size: 42px;
        line-height: 1.15;
    }
}